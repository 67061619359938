<template>
  <loader-view v-if="loading" />

  <v-container v-else>
    <div class="d-flex align-center mb-4">
      <v-btn class="mr-4" icon small to="/users">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <h1 class="text-h5">
        <span class="work-sans">Perfil do usuário</span>
      </h1>

      <v-spacer />

      <v-btn
        color="primary2"
        class="secondary--text"
        depressed
        rounded
        small
        @click="handleViewUser()"
      >
        Ver usuário
      </v-btn>
    </div>

    <div class="d-flex flex-column flex-md-row align-md-center mb-8">
      <v-avatar class="mb-4 mb-md-0 mr-md-6" size="112">
        <v-img :src="_profileImage" />
      </v-avatar>

      <div class="d-flex flex-column mr-4 mb-8 mb-md-0">
        <div class="text-h5 mb-4">
          {{ user.name }}
        </div>

        <div class="text-subtitle-1">
          E-mail: <span class="primary--text">{{ user.email }}</span>
        </div>

        <div class="d-flex">
          <div class="text-subtitle-1 mr-10">
            Código: <span class="primary--text">{{ user.idAfiliado }}</span>
          </div>

          <div v-if="user.quemIndicou" class="text-subtitle-1">
            Indicado por:
            <router-link :to="'/user/' + user.quemIndicou.id">
              {{ user.quemIndicou.name }}
            </router-link>
          </div>
        </div>
      </div>

      <v-spacer />

      <div class="d-flex">
        <div class="d-flex flex-column mr-8">
          <div class="text-h6 text-md-h5 font-weight-regular">
            {{ currencyMask(this.user.saldo) }}
            <span class="text-body-2">BRL</span>
          </div>
          <div class="text-subtitle-2 text-md-subtitle-1 subtext--text">
            Saldo contratual
          </div>
        </div>

        <div class="d-flex mr-8 flex-column">
          <div class="text-h6 text-md-h5 font-weight-regular">
            {{ currencyMask(this.user.saldoComissao) }}
            <span class="text-body-2">BRL</span>
          </div>
          <div class="text-subtitle-2 text-md-subtitle-1 subtext--text">
            Comissões
          </div>
        </div>
      </div>
    </div>

    <FilterGroup
      class="mb-4"
      :filters="routes"
      :routeRoot="'/user/' + user.id"
    />

    <router-view :user="user" @refresh="getData()" />
  </v-container>
</template>

<script>
import { request } from "@/services";
import { currencyMask } from "@/utils";
import FilterGroup from "@/components/filters/FilterGroup";

export default {
  data() {
    return {
      loading: true,
      routes: [
        {
          label: "Perfil",
          value: "",
        },
        {
          label: "Contratos",
          value: "/contracts",
        },
        {
          label: "Comissionamento",
          value: "/commissioning",
        },
        {
          label: "Relatórios",
          value: "/reports",
        },
      ],
      user: null,
    };
  },

  components: {
    FilterGroup,
  },

  beforeMount() {
    this.getData();
  },

  computed: {
    _profileImage() {
      if (
        this.user &&
        this.user.profilePhoto === "assets/pics/profilephoto.jpg"
      )
        return require("@/assets/utils/profile.png");

      return `${this.$store.getters.apiURL}${this.user.profilePhoto}`;
    },

    _commissionPermision() {
      return user.ativaComissao === "1" ? true : false;
    },

    _listData() {
      return [
        {
          value: currencyMask(this.user.saldo) + " BRL",
          label: "Saldo",
        },
        {
          value: currencyMask(this.user.saldoComissao) + " BRL",
          label: "Comissões",
        },
        {
          value: this.user.idAfiliado,
          label: "Cód de convite",
        },
        {
          value: this.user.nivelDeComissoes.qntDepositosAcumulado,
          label: "Dep. Acumulados",
        },
        {
          value: this.user.nivelDeComissoes.comissaoString,
          label: "Taxa de comissão",
        },
        {
          value: this.user.quemIndicou?.name,
          label: "Indicado por",
        },
      ];
    },
  },

  watch: {
    ["$route.params"](val) {
      if (val.id === this.user.id) return;

      this.loading = true;
      this.getData();
    },
  },

  methods: {
    async getData() {
      try {
        const payload = {
          method: "getUserDetail",
          userID: this.$route.params.id,
        };

        await request(payload).then(async (res) => {
          console.log(res);
          this.user = res.userDetail;
        });
      } catch (err) {
        this.$router.go(-1);
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async handleViewUser() {
      try {
        this.loading = true;

        const payload = {
          method: "getToken",
          userID: this.$route.params.id,
        };

        await request(payload).then(async (res) => {
          const url = `https://app.portalterra.online/#/auth?tk=${res.token}`;
          window.open(url, "_self");
        });
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },

    currencyMask,
  },
};
</script>

<style></style>
